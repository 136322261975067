import { core } from "../../../../config/pluginInit";
import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CharacterCount from "@tiptap/extension-character-count";
import { Editor, EditorContent } from "@tiptap/vue-2";
import MenuBar from "../../../ExtraPages/MenuBar.vue";
import Image1 from "@tiptap/extension-image";
import History from "@tiptap/extension-history";
import TextStyle from "@tiptap/extension-text-style";
// import { EventBus } from "./EventBus";

export default {
  name: "blogPost",
  data() {
    return {
      blogPost: {
        id: 0,
        post_heading: "",
        post_description: "",
        meta_title: "",
        company_id: "",
        category: "",
        tags: "",
        og_tc_image: "",
        alt_text: "",
        is_same_og_tc: false,
        post_body: "",
        isCheked: false,
        isDisplay: "",
        url_slug: "",
        minutes_to_read: "",
        og_tc_title: "",
      },
      provider: null,
      editor: null,
      content: "",
      errorMessage: "",
      showPreview: false,
      imageName: "",
      blogUrl: process.env.VUE_APP_BLOG_URL,
      loading: false,
      selectedTags: [],
      tagList: [],
      categoryList: [],
      isHovering: false,
      imagePreviewSrc: "",
      limit: "",
      backUrl: "/blog-post",
      dropzoneOptions: {
        url: "#",
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD ME",
      },
      selectedImages: [],
      isFullScreen: false,
      companyList: [],
      uploadImage: "",
    };
  },
  created() {
    // EventBus.$on("call-upload-image", this.uploadImage);
  },
  components: {
    Multiselect,
    EditorContent,
    MenuBar,
  },
  watch: {
    "blogPost.is_same_og_tc": function (value) {
      if (value) {
        this.blogPost.og_tc_title = this.blogPost.meta_title;
        this.blogPost.og_tc_description = this.blogPost.meta_description;
      } else {
        this.blogPost.og_tc_title = this.blogPost.og_tc_title;
        this.blogPost.og_tc_description = this.blogPost.og_tc_description;
      }
    },
    "blogPost.company_id": function (value) {
      if (value) {
        this.selectedTags = [];
        this.blogPost.category = "";
      }
    },
  },
  computed: {
    isSameOgTcValue() {
      return this.blogPost.is_same_og_tc ? 1 : 0;
    },
    getRules() {
      return this.blogPost.og_tc_image ? "" : "required";
    },
    getDataTitle() {
      return this.imageName ? this.imageName : "Drag your image here";
    },
    wordCount() {
      if (this.blogPost.post_body.trim() === "") {
        return 0;
      }
      return this.blogPost.post_body.trim().split(/\s+/).length;
    },
    // estimatedTime() {
    //   const wordsPerMinute = 50;
    //   const minutes = Math.ceil(this.wordCount / wordsPerMinute);
    //   return minutes;
    // },
    calculateReadingTime1() {
      const wordsPerMinute = 150; // You can adjust this value as needed
      const minutes = Math.ceil(this.wordCount / wordsPerMinute);
      return minutes;
    },
  },

  methods: {
    calculateReadingTime(wordCount) {
      const wordsPerMinute = 150; // You can adjust this value as needed
      const minutes = Math.ceil(wordCount / wordsPerMinute);
      return minutes;
    },
    calculateReadingTimeLabel(wordCount) {
      const minutes = this.calculateReadingTime(wordCount);
      return minutes === 1 ? "minute" : "minutes";
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      const editorElement = document.querySelector(".editor");
      if (editorElement) {
        if (this.isFullScreen) {
          editorElement.style.height = "100vh";
        } else {
          editorElement.style.height = "26rem";
        }
      }
    },
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
    updateIsSameOgTcValue(event) {
      this.blogPost.is_same_og_tc = event.target.checked;
    },
    validateBlogPost() {
      this.loading = true;
      // Adding a comment here to explain the purpose of the setTimeout
      setTimeout(() => {
        this.loading = false;
      }, 10000);
      this.blogPost.id > 0 ? this.update() : this.add();
    },

    add() {
      let _vm = this;
      let fd = new FormData();

      const wordCount = null;
      const tags = [];
      if (_vm.blogPost.og_tc_image) {
        fd.append("image", _vm.blogPost.og_tc_image);
      }
      this.selectedTags.forEach((element) => {
        tags.push(element);
      });
      _vm.blogPost.tags = tags.join(",");
      _vm.blogPost.url_slug = _vm.blogPost.url_slug.toLowerCase();
      _vm.blogPost.is_same_og_tc = _vm.blogPost.is_same_og_tc ? 1 : 0;
      Object.keys(_vm.blogPost).forEach((key) => {
        fd.append(key, _vm.blogPost[key]);
      });
      fd.append("minutes_to_read", this.calculateReadingTime1);
      // Perform a POST request to create a new blog post
      this.axios
        .post("/blog/posts", fd, _vm.blogPost)
        .then(function (response) {
          // Redirect to the specified back URL after successful creation
          _vm.$router.push(_vm.backUrl);
        });
    },

    //Update BlogPost Deatils and Images
    update() {
      let _vm = this;
      let fd = new FormData();
      const tags = [];

      if (_vm.blogPost.og_tc_image) {
        fd.append("image", _vm.blogPost.og_tc_image);
      }
      this.selectedTags.forEach((element) => {
        tags.push(element);
      });
      _vm.blogPost.tags = tags.join(",");
      _vm.blogPost.url_slug = _vm.blogPost.url_slug.toLowerCase();
      _vm.blogPost.is_same_og_tc = _vm.blogPost.is_same_og_tc ? 1 : 0;

      _vm.blogPost.post_body = _vm.editor.getHTML();

      Object.keys(_vm.blogPost).forEach((key) => {
        fd.append(key, _vm.blogPost[key]);
      });
      fd.append("minutes_to_read", this.calculateReadingTime1);
      fd.append("_method", "PATCH");
      this.axios
        .post("/blog/posts/" + this.blogPost.id, fd)
        .then(function (response) {
          // _vm.blogPost = response.data.data.data;
          _vm.$router.push(_vm.backUrl);
        });
    },
    getQueryString() {
      const queryString = "?search=";
      return queryString;
    },

    getDetail() {
      let _vm = this;
      this.axios
        .get("/blog/posts/" + this.$route.params.id)
        .then(function (response) {
          _vm.blogPost = response.data.data;
          _vm.blogPost.post_body = response.data.data.post_body;
          _vm.selectedTags = [];

          _vm.value(_vm.blogPost.post_body);
          const tags = _vm.blogPost.tags.split(",");
          _vm.tagList.forEach((element) => {
            if (tags.indexOf(element) > -1) {
              _vm.selectedTags.push(element);
            }
          });

          if (
            _vm.blogPost.full_image_url &&
            _vm.blogPost.full_image_url.length > 0
          ) {
            _vm.imagePreviewSrc = _vm.blogPost.full_image_url;
            _vm.showPreview = true;
          } else {
            _vm.blogPost.og_tc_image = null;
            _vm.showPreview = false;
          }
        })
        .catch(function (error) {});
    },
    //Get CategoryList On Category Module
    getCategory() {
      const _vm = this;
      this.axios
        .get("/blog/categories-list/" + _vm.blogPost.company_id)
        .then(function (response) {
          _vm.categoryList = response.data.data;
        })
        .catch(function () {});
    },

    //Get TagList On Tag Module
    getTag() {
      let _vm = this;
      // const queryString = this.$route.params.id ? this.$route.params.id : 0;
      _vm.axios
        .get(
          "/blog/tags-list/" + _vm.blogPost.company_id + "/" + _vm.blogPost.id
        )
        .then(function (response) {
          _vm.tagList = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () {});
    },

    openImageInNewTab() {
      let _vm = this;
      if (_vm.blogPost.id > 0) {
        window.open(this.blogPost.full_image_url, "_blank");
      } else {
        return false;
      }
    },

    selectImage(event) {
      let _vm = this;
      var input = event.target;
      if (input.files && input.files[0]) {
        var file = input.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var width = this.width;
            var height = this.height;

            if (width === 1200 && height === 630) {
              _vm.blogPost.og_tc_image = file;

              _vm.imageName = file.name;
              _vm.errorMessage = "";
              _vm.showPreview = true;
              _vm.imagePreviewSrc = e.target.result;
            } else {
              _vm.blogPost.og_tc_image = null;
              _vm.imageName = "";
              _vm.errorMessage =
                "Please select an image with a size of 1200x630 pixels.";
              _vm.showPreview = false;
              _vm.imagePreviewSrc = "";
            }
          };
        };
        reader.readAsDataURL(file);
      }
    },

    getErrorMessage(validationError) {
      if (validationError === "required") {
        return "Image is required.";
      } else {
        return "Please select an image with a size of 1200x630 pixels.";
      }
    },
    resetImage() {
      this.blogPost.og_tc_image = null;
      this.blogPost.full_image_url = null; // Reset the image data
      this.imageName = ""; // Reset the image name
    },
    showResetButton() {
      this.isHovering = true;
    },
    hideResetButton() {
      this.isHovering = false;
    },
    initEditor() {
      this.blogPost.post_body = ""; // Initialize the post_body property

      this.editor = new Editor({
        content: this.blogPost.post_body, // Bind the content to post_body
        extensions: [
          StarterKit.configure({
            history: false,
          }),
          TextStyle.configure({
            fontSize: true,
            fontWeight: true,

            calculateNodeAttributes: ({ fontSize, fontWeight }) => {
              const attributes = {};
              if (fontSize) {
                attributes.style = `font-size: ${fontSize}px;`;
              }
              if (fontWeight) {
                attributes.style += `font-weight: ${fontWeight};`;
              }
              return attributes;
            },
          }),
          Link.configure({
            openOnClick: false, // Set to true if you want the link to open on click
          }),
          CharacterCount,
          BulletList.configure({
            itemTypeName: "listItem",
            keepMarks: true,
            keepAttributes: true,
          }),
          ListItem,
          OrderedList,
          Image1,
          History,
        ],
        onUpdate: () => {
          this.blogPost.post_body = this.editor.getHTML(); // Update the post_body property
          this.$emit("input", this.blogPost);
        },
      });
    },
    updateUrl(event) {
      if (event.code === "Space") {
        const inputText = event.target.value
          .replace(/\s{1,}/g, "-")
          .toLowerCase();
        if (
          this.blogPost.url_slug.length > 1 &&
          this.blogPost.url_slug[this.blogPost.url_slug.length - 2] === "-"
        ) {
          this.blogPost.url_slug = inputText;
        } else {
          this.blogPost.url_slug = inputText;
        }
      }
    },
    getTagAndCategory() {
      let _vm = this;
      _vm.getTag();
      _vm.getCategory();
    },
    getCompany() {
      let _vm = this;
      _vm.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
          _vm.blogPost.company_id = _vm.blogPost.company_id
            ? _vm.blogPost.company_id
            : _vm.companyList[0].id;
          _vm.getTagAndCategory();
        })
        .catch(function () {});
    },
  },
  mounted() {
    let _vm = this;
    core.index();
    if (_vm.$route.params.id !== undefined && _vm.$route.params.id !== "") {
      _vm.getDetail();
    }
    _vm.getCompany();

    _vm.initEditor();
  },
};
