<template>
  <div>

    <div v-for="(item, index) in items" :key="index">
      <div class="divider" v-if="item.type === 'divider'" />
      <menu-item v-else-if="item.type === 'link'" :editor="editor" v-bind="item" />
      <menu-item v-else v-bind="item" />
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
import 'remixicon/fonts/remixicon.css';

export default {
  components: {
    MenuItem,
  },

  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageName: '',
      showPreview: false,
      imagePreviewSrc: '',
      items: [
//       {
//   icon: 'font-size',
//   title: 'Font Size',
//   action: () => {
//     const fontSize = prompt('Enter the font size:');
//     if (fontSize) {
//       this.editor.chain().focus().setAttributes({ fontSize }).run();
//     }
//   },
// },

      {
  icon: 'font-size',
  title: 'Font Size',
  action: () => {
    const fontSize = prompt('Enter the font size:');
    if (fontSize) {
      this.editor.chain().focus().setMark('textStyle', { fontSize }).run();
      
    }
  },
},
        {
          icon: 'bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'h-2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 }),
        },
        {
          icon: 'h-3',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 }),
        },
        {
          icon: 'h-4',
          title: 'Heading 4',
          action: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 4 }),
        },
        {
          icon: 'h-5',
          title: 'Heading 5',
          action: () => this.editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 5 }),
        },
        {
          icon: 'h-6',
          title: 'Heading 6',
          action: () => this.editor.chain().focus().toggleHeading({ level: 6 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 6 }),
        },
        {
          icon: 'paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          icon: 'list-unordered',
          title: 'Bullet List',
          action: () => this.editor.commands.toggleBulletList(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'list-ordered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          icon: 'double-quotes-l',
          title: 'Blockquote',
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
          isActive: () => this.editor.isActive('blockquote'),
        },
        {
          icon: 'image-add-line',
          title: 'Set Image',
          action: () => {
            this.altText = '';
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.style.display = 'none';

            input.addEventListener('change', (event) => {
              this.uploadImage(event);
            });

            document.body.appendChild(input);
            input.click();
            // Your image set action logic here
          },
          isActive: () => false,
        },
        {
          type: 'divider',
        },
        {
          icon: 'arrow-go-back-line',
          title: 'Undo',
          action: () => this.editor.commands.undo(),
        },
        {
          icon: 'arrow-go-forward-line',
          title: 'Redo',
          action: () => this.editor.commands.redo(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'link-unlink',
          title: 'Link Unset',
          action: () => this.editor.commands.unsetLink(),
        },
      ],
    }
  },
  computed: {
    linkItem() {
      return {
        icon: 'link',
        title: 'Link',
        action: () => {
          const url = prompt('Enter the URL:');
          if (url) {
            this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
          }
        },
        isActive: () => this.editor.isActive('link'),
      };
    },
  },

  watch: {
    editor: {
      handler(newEditor) {
        this.items = [...this.items.filter(item => item.type !== 'link'), this.linkItem];
      },
      immediate: true,
    },
  },
  methods: {

    uploadImage(event) {
      event.preventDefault();
      const file = event.target.files[0];
      if (file) {
        const altText = prompt('Enter alt text for the image:');
              if (!altText) {
                return;
              }
        const formData = new FormData();
        formData.append('image', file);
        this.axios.post('/blog/store-body-image', formData)
          .then((response) => {
            if (response.data) {
              const imagePath = response.data.data;
              this.imagePreviewSrc = imagePath;

              this.editor
                .chain()
                .focus()
                .setImage({ src: imagePath })
                .run();
             
              const newImageTag = document.createElement("img");
              newImageTag.src = imagePath;
              newImageTag.alt = altText;
              newImageTag.style.width = "500px";
              newImageTag.style.height = "50px";

              this.editor
                .chain()
                .focus()
                .insertContent(newImageTag.outerHTML)
                .run();

            } 
          })
          .catch((error) => {
          });      
      }
    },
  },
}
</script>
<style lang="scss">
.editor {
  background-color: #FFF;
  border: 3px solid #0D0D0D;
  border-radius: 0.75rem;
  color: #0D0D0D;
  display: flex;
  flex-direction: column;
  height: 26rem;
  position: relative;

  &__header {
    align-items: center;
    background: #0d0d0d;
    border-bottom: 3px solid #0d0d0d;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    padding: 0.25rem;
  }

  &__content {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1.25rem 1rem;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    align-items: center;
    border-top: 3px solid #0D0D0D;
    color: #0D0D0D;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    font-size: 12px;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.25rem 0.75rem;
    white-space: nowrap;
  }

  &__status {
    align-items: center;
    border-radius: 5px;
    display: flex;

    &::before {
      background: rgba(#0D0D0D, 0.5);
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      flex: 0 0 auto;
      height: 0.5rem;
      margin-right: 0.5rem;
      width: 0.5rem;
    }

    &--connecting::before {
      background: #616161;
    }

    &--connected::before {
      background: #B9F18D;
    }
  }

  &__name {
    button {
      background: none;
      border: none;
      border-radius: 0.4rem;
      color: #0D0D0D;
      font: inherit;
      font-size: 12px;
      font-weight: 600;
      padding: 0.25rem 0.5rem;

      &:hover {
        background-color: #0D0D0D;
        color: #FFF;
      }
    }
  }
}

.ProseMirror {
  outline: none !important;
  border: 0 none !important;
  min-height: 310px !important;
}

.editor_height {
  min-height: 350px;
}

.collaboration-cursor__caret {
  border-left: 1px solid #0D0D0D;
  border-right: 1px solid #0D0D0D;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0D0D0D;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.ProseMirror {
  >*+* {
    margin-top: 0.75em;
  }

  ul {
    list-style: disc !important;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    border-radius: 0.5rem;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  mark {
    background-color: #FAF594;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    border-left: 2px solid rgba(#0D0D0D, 0.1);
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }

  // ul[data-type="taskList"] {
  //   list-style: none;
  //   padding: 0;

  //   li {
  //     align-items: center;
  //     display: flex;

  //     >label {
  //       flex: 0 0 auto;
  //       margin-right: 0.5rem;
  //       user-select: none;
  //     }

  //     >div {
  //       flex: 1 1 auto;
  //     }
  //   }
  // }
}

.editor__fullscreen-button {
  background: none;
  border: none;
  border-radius: 0.4rem;
  color: #0D0D0D;
  font: inherit;
  font-size: 12px;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #0D0D0D;
    color: #FFF;
  }
}

.divider {
  background-color: rgba(#fff, 0.25);
  height: 1.25rem;
  margin-left: 0.5rem;
  margin-right: 0.75rem;
  width: 1px;
}
</style>
